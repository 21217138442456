export type UpdateGroupError = {
  name?: Array<string> | string;
  imei_list?: {
    [key: string]: Array<string>;
  };
  ranges?: Array<RangeError>;
};

type RangeError = {
  range: Array<string>;
};

/**
 * Вынимает ошибки обновления группы IMEI.
 */
export function parseUpdateGroupErrors(errors: any) {
  const { imei_list, ranges, ...unknownErrors } = errors as UpdateGroupError;

  let imeiListError: Array<[string, string[]]> = imei_list
    ? Object.entries(imei_list)
    : [];
  let rangesError: Array<RangeError> =
    ranges && Array.isArray(ranges) ? ranges : [];

  const imeiListErrorDescription = parseIMEIListErrors(imeiListError);
  const rangesErrorDescription = parseRangesError(rangesError);

  return {
    imeiListErrorDescription,
    rangesErrorDescription,
    unknownErrors,
  };
}

function parseIMEIListErrors(
  entries: Array<[string, string[]]>,
): Array<string> {
  const imeiErrors = entries.map(([index, descriptions]) => {
    return descriptions.map((description) => {
      if (description.startsWith('Ensure this value is greater than')) {
        return `IMEI под номером ${
          Number(index) + 1
        } в списке должен иметь значение больше 100000000000000`;
      }

      return `Ошибка в значении IMEI под номером ${index}`;
    });
  });

  return imeiErrors.flat();
}

function parseRangesError(errors: Array<RangeError>): Array<string> {
  const rangeErrors = errors.map((error) => {
    return error.range.map((errorDescription) => {
      if (
        errorDescription.startsWith(
          `Ensure that the lower bound of the range is not less than`,
        )
      ) {
        return `Нижняя граница диапазона должна быть больше 100000000000000`;
      }

      return `Ошибка в значении диапазона`;
    });
  });

  console.log(`🔰 ranges error entries`, errors);
  return rangeErrors.flat();
}
