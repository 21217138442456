import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from '@/features/hooks';
import { isUserLoggedInSelector } from '@/features/user/user.selectors';

import { ProtectedRouteProps } from './ProtectedRoute.types';

/**
 * Родительский компонент, для путей, защищенных доступом по логину
 */
function ProtectedRoute({ redirectTo = '/' }: ProtectedRouteProps) {
  const isLoggedIn = useAppSelector(isUserLoggedInSelector);

  // Возвращаем пользователя, если он не авторизован
  if (!isLoggedIn) {
    return <Navigate to={redirectTo} replace={true} />;
  }

  return <Outlet></Outlet>;
}

export default React.memo(ProtectedRoute);
