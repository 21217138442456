import {
  IMEI_LENGTH,
  IMEINumberToString,
  IMEIRange,
  IMEIStringToNumber,
} from '@/features/imei';

import { IMEIStringRange } from './IMEIRanges.types';

/**
 * Конвертирует массив цифровых диапазонов, отражающих IMEI в
 * строковые, используемые UI компонентом IMEIRanges.
 * @param ranges
 */
export function IMEINumberRangesToStringRanges(
  ranges: Array<IMEIRange>,
): Array<IMEIStringRange> {
  return ranges.map(({ range }) => ({
    start: IMEINumberToString(range.lower),
    end: IMEINumberToString(range.upper),
  }));
}

/**
 * Возвращает true, если конец диапазона больше начала.
 */
export function isEndBeforeStart(
  start: string | undefined,
  end: string | undefined,
) {
  if (start === undefined || end === undefined || start === '' || end === '') {
    return false;
  }

  return Number(end) - Number(start) < 0;
}

/**
 * Возвращает индексы пересекающихся диапазонов.
 */
export function checkRangesIntersection(
  ranges: Array<IMEIStringRange> | undefined,
): Array<number> {
  if (!ranges) {
    return [];
  }

  const clashedRanges: Array<number> = [];

  ranges.forEach((range1, range1Index) => {
    ranges.forEach((range2, range2Index) => {
      if (range1 === range2) {
        return;
      }

      if (
        range1.start === undefined ||
        range2.start === undefined ||
        range1.end === undefined ||
        range2.end === undefined
      ) {
        return;
      }

      if (
        range1.start.length < IMEI_LENGTH ||
        range2.start.length < IMEI_LENGTH ||
        range1.end.length < IMEI_LENGTH ||
        range2.end.length < IMEI_LENGTH
      ) {
        return;
      }

      if (
        Number.isNaN(Number(range1.start.length)) ||
        Number.isNaN(Number(range2.start.length)) ||
        Number.isNaN(Number(range1.end.length)) ||
        Number.isNaN(Number(range2.end.length))
      ) {
        return;
      }

      const smallerRange =
        IMEIStringToNumber(range1.start) < IMEIStringToNumber(range2.start)
          ? range1
          : range2;
      const smallerRangeIndex =
        IMEIStringToNumber(range1.start) < IMEIStringToNumber(range2.start)
          ? range1Index
          : range2Index;
      const biggerRange =
        IMEIStringToNumber(range1.start) >= IMEIStringToNumber(range2.start)
          ? range1
          : range2;
      const biggerRangeIndex =
        IMEIStringToNumber(range1.start) >= IMEIStringToNumber(range2.start)
          ? range1Index
          : range2Index;

      if (
        IMEIStringToNumber(smallerRange.end!) <
        IMEIStringToNumber(biggerRange.start!)
      ) {
        return;
      }

      if (!clashedRanges.includes(smallerRangeIndex)) {
        clashedRanges.push(smallerRangeIndex);
      }

      if (!clashedRanges.includes(biggerRangeIndex)) {
        clashedRanges.push(biggerRangeIndex);
      }
    });
  });

  return clashedRanges;
}

/** Функция для отображения сообщения об ошибке границ диапазона */
export function isStartBeforeEndValidator(start: string, end: string) {
  if (
    !start ||
    start.length < IMEI_LENGTH ||
    !end ||
    end.length < IMEI_LENGTH
  ) {
    return Promise.resolve();
  }

  if (Number.isNaN(Number(start) || Number.isNaN(Number(end)))) {
    return Promise.resolve();
  }

  const isInvalid = isEndBeforeStart(start, end);

  if (isInvalid) {
    return Promise.reject(
      new Error('Конец диапазона должен быть больше начала'),
    );
  }

  return Promise.resolve();
}

/**
 * Тип ошибки, которая возникает, при разборе текстового диапазона в цифровой.
 */
export class IMEIStringRangeToIMEIRangeError extends Error {
  incorrectValue: IMEIStringRange;

  constructor(message: string, value: IMEIStringRange) {
    super(message);
    this.incorrectValue = value;
  }
}

export type IMEIStringRangeToIMEIRangeErrorType =
  typeof IMEIStringRangeToIMEIRangeError;

/**
 * Конвертирует строковое представление диапазона в числовое, используемое системой.
 * @param stringIMEI
 */
export function IMEIStringRangeToIMEIRange(
  stringIMEI: IMEIStringRange,
): IMEIRange {
  if (!stringIMEI.start || stringIMEI.start.length !== IMEI_LENGTH) {
    throw new IMEIStringRangeToIMEIRangeError(
      'Некорректная длина начала диапазона',
      stringIMEI,
    );
  }

  if (!stringIMEI.end || stringIMEI.end.length !== IMEI_LENGTH) {
    throw new IMEIStringRangeToIMEIRangeError(
      'Некорректная длина окончания диапазона',
      stringIMEI,
    );
  }

  const rangeStart = Number(stringIMEI.start);

  if (Number.isNaN(rangeStart)) {
    throw new IMEIStringRangeToIMEIRangeError(
      'Некорректный формат начала диапазона',
      stringIMEI,
    );
  }

  const rangeEnd = Number(stringIMEI.end);

  if (Number.isNaN(rangeEnd)) {
    throw new IMEIStringRangeToIMEIRangeError(
      'Некорректный формат окончания диапазона',
      stringIMEI,
    );
  }

  return {
    range: {
      lower: rangeStart,
      upper: rangeEnd,
    },
  };
}

/**
 * Конвертирует строковое представление диапазонов в числовое.
 */
export function IMEIsStringRangeToIMEIRange(ranges: Array<IMEIStringRange>) {
  return ranges.map(IMEIStringRangeToIMEIRange);
}

/**
 * Возвращает строковое представление диапазона.
 * Пример: `0000000000000001 - 0000000000000019`
 */
export function IMEIRangeToString({ range }: IMEIRange): string {
  return `${IMEINumberToString(range.lower)} - ${IMEINumberToString(
    range.upper,
  )}`;
}
