import { createContext } from 'react';

import { NotificationContextProps } from './notifications.types';

const NotificationContext = createContext<NotificationContextProps>({
  notificationApi: null,
  showError: (
    message: string | React.ReactElement,
    description?: string | React.ReactElement | Array<string>,
  ) => {},
  showSuccess: (
    message: string | React.ReactElement,
    description?: string | React.ReactElement | Array<string>,
  ) => {},
  showWarning: (
    message: string | React.ReactElement,
    description?: string | React.ReactElement | Array<string>,
  ) => {},
});

export default NotificationContext;
