/**
 * Возвращает объект, содержащий уникальные значения массива и
 * дубликаты в единственном числе.
 * Повторяющиеся значения удаляются начиная с первого повтора.
 * Т.е. первое из таких значений считается уникальным, и остается на своем месте.
 *
 * @param values массив значений, где необходимо произвести поиск.
 */
function getUnique<T>(values: Array<T>): {
  unique: Array<T>;
  duplicates: Array<T>;
} {
  const result: Array<T> = [];
  const duplicates: Array<T> = [];

  values.forEach((value) => {
    if (!result.includes(value)) {
      result.push(value);
      return;
    }

    if (!duplicates.includes(value)) {
      duplicates.push(value);
    }
  });

  return {
    unique: result,
    duplicates,
  };
}

export default getUnique;
