import { createBrowserRouter } from 'react-router-dom';

import {
  DashboardRoot,
  Group,
  GroupNew,
  Groups,
  Login,
  LoginRoot,
  LogOut,
  NotFound,
  Profile,
  ProtectedRoute,
  Root,
  Search,
} from '@/routes';

const notFoundRoute = {
  element: <NotFound />,
  path: '*',
};

const noLoginRoutes = {
  element: <LoginRoot />,
  children: [
    {
      index: true,
      element: <Login />,
    },
    {
      path: 'logout',
      element: <LogOut />,
    },
  ],
};

const authorizedOnlyRoutes = {
  element: <ProtectedRoute />,
  children: [
    {
      element: <DashboardRoot />,
      children: [
        {
          children: [
            {
              path: 'groups',
              element: <Groups />,
            },
            {
              path: '/groups/new',
              element: <GroupNew />,
            },
            {
              path: '/groups/:id',
              element: <Group />,
            },
            {
              path: 'search',
              element: <Search />,
            },
            {
              path: 'profile',
              element: <Profile />,
            },
          ],
        },
      ],
    },
  ],
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [notFoundRoute, noLoginRoutes, authorizedOnlyRoutes],
  },
]);

export default router;
