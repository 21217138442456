import { createSelector } from '@reduxjs/toolkit';

import { UserState } from './user.types';

const isUserLoggedInSelector = createSelector(
  (state: { user: UserState }) => state.user,
  (user) => {
    return Boolean(user.accessToken && user.refreshToken);
  },
);

const userMailSelector = createSelector(
  (state: { user: UserState }) => state.user,
  (user) => user.email,
);

export { isUserLoggedInSelector, userMailSelector };
