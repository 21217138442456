import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ContentCard, PaginationTotal, Table } from '@aq_mobile/ui-kit';
import {
  MagnifyingGlassIcon,
  PlusIcon,
  TrashIcon,
} from '@aq_mobile/ui-kit/icons';
import styled from '@emotion/styled';
import {
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Spin,
  TableColumnsType,
  TablePaginationConfig,
} from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import localeRU from 'antd/locale/ru_RU';

import { IMEIGroup, useGroupEdit, useGroups } from '@/features/groups';
import filterByTerm from '@/utils/filterByTerm';
import {
  getSortColumnSettings,
  setSortColumnSettings,
} from '@/utils/sortColumnSettings';

const TableStyled = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
` as unknown as typeof Table;

const SORT_COLUMN_SETTING_PATH = 'groupsSortColumn';

const columnsTemplate: TableColumnsType<IMEIGroup> = [
  {
    key: 'id',
    dataIndex: 'id',
    title: 'ID',
    defaultSortOrder: getSortColumnSettings(SORT_COLUMN_SETTING_PATH, 'id'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a, b) => a.id - b.id,
    width: 100,
  },
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Группа IMEI',
    defaultSortOrder: getSortColumnSettings(
      SORT_COLUMN_SETTING_PATH,
      'name',
      'ascend',
    ),
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
];

const PAGE_SIZE = 10;

/**
 * Страница со списком групп
 */
function Groups() {
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { groups, isGroupsLoading } = useGroups();
  const { deleteGroup, isGroupDeleting } = useGroupEdit();

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>('');

  const rowSelection = useMemo(() => {
    return { selectedRowKes: selectedRowKeys, onChange: setSelectedRowKeys };
  }, [selectedRowKeys]);

  const handleDelete = useCallback(() => {
    selectedRowKeys.forEach((id) => {
      deleteGroup(id as number);
    });

    setSelectedRowKeys([]);
  }, [deleteGroup, selectedRowKeys]);

  const handleGoToGroup = useCallback(
    ({ id }: { id: number }) => {
      navigate(`${id}`);
    },
    [navigate],
  );

  const handleNewGroup = useCallback(() => {
    navigate('new');
  }, [navigate]);

  const tableData = useMemo(() => {
    if (!groups.length) {
      return [];
    }

    const nameFiltered = filterByTerm(groups, searchTerm, 'name');

    return nameFiltered;
  }, [groups, searchTerm]);

  const AddButtonTemplate = useMemo(
    () => (
      <Button type="primary" icon={<PlusIcon />} onClick={handleNewGroup}>
        группа IMEI
      </Button>
    ),
    [handleNewGroup],
  );

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IMEIGroup> | SorterResult<IMEIGroup>[],
    ) => {
      if (Array.isArray(sorter) || !sorter.columnKey) {
        return;
      }

      if (!['name', 'id'].includes(sorter.columnKey.toString())) {
        return null;
      }

      setSortColumnSettings(
        SORT_COLUMN_SETTING_PATH,
        sorter.columnKey.toString(),
        sorter.order ?? null,
      );
    },
    [],
  );

  const isFormInProgress = isGroupsLoading || isGroupDeleting;

  const TableControlsTemplate = useMemo(() => {
    const DeleteButton =
      selectedRowKeys.length > 0 ? (
        <Popconfirm
          title="Удаление группы"
          description={
            selectedRowKeys.length > 1
              ? 'Удалить выбранные группы?'
              : 'Удалить выбранную группу?'
          }
          okText="Удалить"
          cancelText="Отмена"
          onConfirm={handleDelete}
        >
          <Button
            icon={<TrashIcon />}
            type="primary"
            title="Удалить выбранные"
          />
        </Popconfirm>
      ) : null;

    return DeleteButton;
  }, [handleDelete, selectedRowKeys.length]);

  return (
    <ContentCard>
      <ContentCard.Header title="Группы IMEI" buttons={AddButtonTemplate} />

      <ContentCard.Body>
        <Row gutter={24} align={'middle'} style={{ marginBottom: 8 }}>
          <Col>{TableControlsTemplate}</Col>
          <Col flex="auto"></Col>
          <Col>
            <Space>
              <Input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Поиск"
                suffix={<MagnifyingGlassIcon />}
                allowClear
              />

              <PaginationTotal
                currentPage={currentTablePage}
                pageSize={PAGE_SIZE}
                total={tableData.length || 0}
              />
            </Space>
          </Col>
        </Row>

        <Spin spinning={isFormInProgress}>
          <TableStyled
            rowSelection={rowSelection}
            dataSource={tableData}
            columns={columnsTemplate}
            rowKey={'id'}
            pagination={{
              pageSize: PAGE_SIZE,
              hideOnSinglePage: false,
              onChange(page) {
                setCurrentTablePage(page);
              },
            }}
            onChange={handleTableChange}
            locale={localeRU.Table}
            onRow={(record) => {
              return {
                onClick: (e) => {
                  handleGoToGroup(record);
                },
              };
            }}
            scroll={{ x: 480 }}
          />
        </Spin>
      </ContentCard.Body>
    </ContentCard>
  );
}

export default Groups;
