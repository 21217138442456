import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, ContentCard } from '@aq_mobile/ui-kit';
import { ArrowDownToLineIcon } from '@aq_mobile/ui-kit/icons';
import { Form, Input, Space, Spin } from 'antd';

import { ErrorList } from '@/components';
import { ErrorRecord } from '@/components/ErrorList/ErrorList.types';
import {
  errorsToErrorsList,
  shouldBeEmptyValidator,
} from '@/components/ErrorList/ErrorList.utils';
import { IMEIImportModal } from '@/components/IMEIImportModal';
import { IMEIImportResult } from '@/components/IMEIImportModal/IMEIImportModal.types';
import { IMEIInput } from '@/components/IMEIInput';
import IMEIRanges from '@/components/IMEIRanges/IMEIRanges';
import { IMEIStringRange } from '@/components/IMEIRanges/IMEIRanges.types';
import {
  IMEINumberRangesToStringRanges,
  IMEIRangeToString,
  IMEIsStringRangeToIMEIRange,
  IMEIStringRangeToIMEIRange,
} from '@/components/IMEIRanges/IMEIRanges.utils';
import { GROUP_NAME_MAX_LENGTH } from '@/constants';
import { useGroup, useGroupEdit } from '@/features/groups';
import {
  IMEINumberToString,
  IMEIsToString,
  isIMEIInRange,
  stringToIMEIs,
} from '@/features/imei';
import { NotificationContext } from '@/features/notifications';
import getUnique from '@/utils/getUnique';

type IMEIForm = {
  name: string;
  imei: string;
  range: Array<IMEIStringRange>;
  errors: Array<ErrorRecord>;
};

/**
 * Форма редактирования группы IMEI.
 */
function Group() {
  const { id: groupIdParam } = useParams();
  const id = Number(groupIdParam);
  const navigate = useNavigate();
  const location = useLocation();
  const { group, isGroupLoading } = useGroup(id);
  const { updateGroup, isGroupUpdating } = useGroupEdit();
  const notificationContext = useContext(NotificationContext);
  const [form] = Form.useForm<IMEIForm>();
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [hasImportErrors, setHasImportErrors] = useState(false);
  const handleSaveClick = useCallback(() => {
    form.submit();
  }, [form]);

  useEffect(() => {
    // Проставляем новые значения группы IMEI, как только они получены с сервера.
    if (!group) {
      return;
    }

    const { name, imei_list, ranges } = group;
    const imei = Array.isArray(imei_list) ? IMEIsToString(imei_list) : '';
    const range = Array.isArray(ranges)
      ? IMEINumberRangesToStringRanges(ranges)
      : [];

    form.setFieldsValue({
      name,
      imei,
      range,
    });
  }, [form, group]);

  const handleImportOpen = useCallback(() => {
    form
      .validateFields()
      .then(() => {
        setIsImportOpen(true);
      })
      .catch((e) => {
        notificationContext.showError(
          'Импорт невозможен',
          'Сначала нужно исправить ошибки на форме редактирования группы.',
        );
      });
  }, [form, notificationContext]);

  const isFormPending = isGroupLoading || isGroupUpdating;

  const importButtonTemplate = useMemo(
    () => (
      <Button
        type="link"
        icon={
          <ArrowDownToLineIcon
            style={{
              width: '1.23em',
              height: '1.23em',
            }}
          />
        }
        onClick={handleImportOpen}
        disabled={isFormPending}
      >
        Импорт
      </Button>
    ),
    [handleImportOpen, isFormPending],
  );

  /**
   * Обработчик изменения списка ошибок импорта.
   * Используется для скрытия элемента списка, вызывается
   * каждый раз, когда значение компонента изменяется.
   */
  const handleImportErrorsChanged = useCallback(() => {
    const importErrors = form.getFieldValue('errors') as
      | Array<ErrorRecord>
      | undefined;

    if (!importErrors) {
      setHasImportErrors(false);
      return;
    }

    setHasImportErrors(true);
  }, [form]);

  /**
   *  Обработчик результатов импорта.
   * Собирает ошибки импорта и вставляет результирующие IMEI в список IMEI.
   * Для того, чтобы импорт сработал корректно, форма должна быть валидна.
   */
  const handleImport = useCallback(
    ({ imei: importIMEI, errors: importErrors }: IMEIImportResult) => {
      setIsImportOpen(false);

      // Массив IMEI номеров включающий, список, указанный на форме и импортированные
      const correctIMEIs: Array<number> = [];
      // Список ошибок импорта
      const errors: Array<string> = [];

      if (importErrors.length) {
        importErrors.forEach((error) => {
          errors.push(`Не распознано значение "${error}"`);
        });
        setHasImportErrors(true);
      }

      // Если кроме ошибок импорта больше ничего нет.
      if (!importIMEI.length && errors.length) {
        form.setFieldValue('errors', errorsToErrorsList(errors));
        notificationContext.showError(
          'Ошибка импорта',
          `Нет распознанных IMEI. Количество ошибок ${errors.length}, с.м. раздел "Ошибки импорта"`,
        );
        return;
      }

      // Если нет ошибок импорта и распознанных номеров.
      if (!importIMEI.length && !errors.length) {
        notificationContext.showWarning(
          'Импорт завершен',
          'В файле отсутствуют IMEI номера',
        );
        return;
      }

      const formIMEIsString = form.getFieldValue('imei');
      const formIMEIList = stringToIMEIs(formIMEIsString);
      const formIMEIStringRanges = form.getFieldValue(
        'range',
      ) as Array<IMEIStringRange>;
      const ranges = formIMEIStringRanges.map((range) =>
        IMEIStringRangeToIMEIRange(range),
      );

      // Определяем возможные дубликаты, среди импортированных номеров.
      const { unique: uniqueIMEIs, duplicates } = getUnique([
        ...formIMEIList,
        ...importIMEI,
      ]);

      // Дубликаты добавляются в список ошибок.
      duplicates.forEach((duplicate) => {
        errors.push(
          `IMEI ${IMEINumberToString(duplicate)} уже присутствует в списке`,
        );
      });

      // Проверяем попадание номеров в имеющиеся диапазоны
      uniqueIMEIs.forEach((imei) => {
        let isIncludedInRange = false;
        ranges.forEach((range) => {
          if (!isIMEIInRange(imei, range)) {
            return;
          }

          errors.push(
            `IMEI ${IMEINumberToString(
              imei,
            )} входит в диапазон ${IMEIRangeToString(range)}`,
          );
          isIncludedInRange = true;
        });

        if (!isIncludedInRange) {
          correctIMEIs.push(imei);
        }
      });

      form.setFieldValue('imei', IMEIsToString(correctIMEIs));
      form.setFieldValue('errors', errorsToErrorsList(errors));

      if (errors.length) {
        setHasImportErrors(true);
        notificationContext.showWarning(
          'Импорт завершен с ошибками',
          `Прочитано ${importIMEI.length} IMEI. Ошибок импорта ${errors.length}`,
        );
        return;
      }

      notificationContext.showSuccess(
        'Импорт завершен',
        `Прочитано ${importIMEI.length} IMEI.`,
      );
      return;
    },
    [form, notificationContext],
  );

  const handleImportCancel = useCallback(() => setIsImportOpen(false), []);

  /**
   * Обработчик поведения нажатия на кнопку "Назад".
   */
  const handleBack = useCallback(() => {
    // Переменная location.state имеет значение, если переход на форму
    // был осуществлен из раздела поиска.
    // Значение state-а это строка поиска, которую нужно будет восстановить.
    if (location.state) {
      navigate('/search', { state: location.state });
      return;
    }

    //
    navigate('/groups');
  }, [location.state, navigate]);

  const handleSubmit = useCallback(
    async (values: IMEIForm) => {
      const name = values.name;
      const imei_list = stringToIMEIs(values.imei);
      const ranges = IMEIsStringRangeToIMEIRange(values.range);

      await updateGroup({
        id,
        name,
        imei_list,
        ranges,
      }).unwrap();

      notificationContext.showSuccess('Изменения сохранены');
    },
    [id, notificationContext, updateGroup],
  );

  return (
    <>
      <ContentCard>
        <ContentCard.Header
          title="Редактирование группы IMEI"
          buttons={importButtonTemplate}
        />
        <ContentCard.Body>
          <Spin spinning={isFormPending}>
            <Form
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              colon={false}
              labelAlign="left"
              onFinish={handleSubmit}
            >
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Название',
                  },
                  {
                    max: GROUP_NAME_MAX_LENGTH,
                    message: `Превышена максимальная длина названия ${GROUP_NAME_MAX_LENGTH}`,
                  },
                ]}
                label="Название"
              >
                <Input />
              </Form.Item>
              <IMEIInput name="imei" rangesInputName="range" />
              <IMEIRanges name="range" />
              <Form.Item
                wrapperCol={{ span: 24 }}
                name="errors"
                rules={[() => ({ validator: shouldBeEmptyValidator })]}
                hidden={!hasImportErrors}
              >
                <ErrorList onChange={handleImportErrorsChanged} />
              </Form.Item>
            </Form>
          </Spin>
        </ContentCard.Body>
        <ContentCard.Footer>
          <Space>
            <Button onClick={handleBack}>Назад</Button>
            <Button
              type="primary"
              onClick={handleSaveClick}
              disabled={isFormPending}
            >
              Сохранить
            </Button>
          </Space>
        </ContentCard.Footer>
      </ContentCard>
      <IMEIImportModal
        open={isImportOpen}
        onOk={handleImport}
        onCancel={handleImportCancel}
      />
    </>
  );
}

export default Group;
