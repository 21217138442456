export const IMEI_LENGTH = 15;
export const IMEI_MIN_NUMBER = 10 ** (IMEI_LENGTH - 1);

export type IMEIRange = {
  id?: number;
  range: {
    lower: number;
    upper: number;
  };
};
