import { Button, Typography } from '@aq_mobile/ui-kit';
import { ExclamationMarkCircleOutlineIcon } from '@aq_mobile/ui-kit/icons';
import { MARGINS } from '@aq_mobile/ui-kit/utils';
import { Col, Form, Input, Popconfirm, Row, Space, Tag } from 'antd';

import { isStartBeforeEndValidator } from '@/components/IMEIRanges/IMEIRanges.utils';
import { IMEI_LENGTH } from '@/features/imei';

import { IMEIRangeProps } from './IMEIRange.types';

/**
 * Один элемент диапазона
 */
function IMEIRange({
  formItemName,
  startName,
  endName,
  number,
  onDelete,
  isReadonly = false,
  hasError = false,
}: IMEIRangeProps) {
  const isError = hasError;

  return (
    <div>
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ marginBlockEnd: MARGINS.element }}
      >
        <Col>
          <Space>
            <Typography.TextM>Диапазон IMEI:</Typography.TextM>
            <Tag color="default">&#35;{number}</Tag>
          </Space>
        </Col>
        <Col>
          {!isReadonly && (
            <Popconfirm
              title="Удаление диапазона"
              description="Удалить выбранный диапазон?"
              okText="Удалить"
              cancelText="Отмена"
              onConfirm={() => onDelete?.(number - 1)}
            >
              <Button type="primary" danger size="small">
                Удалить
              </Button>
            </Popconfirm>
          )}
        </Col>
      </Row>

      <Form.Item
        label="Диапазон IMEI"
        tooltip={{
          title: 'Верхняя граница диапазона включается',
          icon: <ExclamationMarkCircleOutlineIcon />,
        }}
      >
        <Space.Compact style={{ width: '100%' }}>
          <Form.Item
            noStyle
            name={startName}
            validateTrigger={'onBlur'}
            rules={[
              {
                len: IMEI_LENGTH,
                message: `Некорректная длина начала диапазона`,
              },
              {
                pattern: /^\d*$/,
                message: 'IMEI должен содержать только числа',
              },
              {
                required: true,
                message: 'Начало диапазона должно быть заполнено',
              },
            ]}
          >
            <Input
              placeholder="Начало"
              count={{
                show: true,
              }}
              minLength={IMEI_LENGTH}
              maxLength={IMEI_LENGTH}
              status={isError ? 'error' : undefined}
            />
          </Form.Item>
          <Form.Item
            noStyle
            name={endName}
            validateTrigger={'onBlur'}
            rules={[
              {
                len: IMEI_LENGTH,
                message: `Некорректная длина окончания диапазона`,
              },
              {
                pattern: /^\d*$/,
                message: 'IMEI должен содержать только числа',
              },
              {
                required: true,
                message: 'Конец диапазона должен быть заполнен',
              },
              ({ getFieldValue }) => {
                const nameStart = Array.isArray(formItemName)
                  ? formItemName
                  : [formItemName];

                const startValue = getFieldValue([...nameStart, ...startName]);
                return {
                  validator(_, endValue) {
                    return isStartBeforeEndValidator(startValue, endValue);
                  },
                };
              },
            ]}
            dependencies={[startName]}
          >
            <Input
              placeholder="Конец"
              count={{
                show: true,
              }}
              minLength={IMEI_LENGTH}
              maxLength={IMEI_LENGTH}
              status={isError ? 'error' : undefined}
            />
          </Form.Item>
        </Space.Compact>
      </Form.Item>
    </div>
  );
}

export default IMEIRange;
