import { useState } from 'react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import { Content, Header, Sider, SiderCollapseType } from '@/components';
import { MENU_ITEMS } from '@/features/menuItems';

/**
 * Компонент общий компонент, соответствующий пути "/", после логина пользователя.
 */
function DashboardRoot() {
  const [collapsedType, setCollapsedType] =
    useState<SiderCollapseType>('manual');
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    setCollapsedType('manual');
  };

  const setCollapse = (collapsed: boolean, type: SiderCollapseType) => {
    setIsCollapsed(collapsed);
    setCollapsedType(type);
  };

  return (
    <Layout>
      <Sider
        collapsed={isCollapsed}
        collapsedType={collapsedType}
        onCollapse={setCollapse}
        items={MENU_ITEMS}
      />
      <Layout>
        <Header
          isSiderCollapsed={isCollapsed}
          onCollapseClick={toggleCollapse}
        />
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default React.memo(DashboardRoot);
