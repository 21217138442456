import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { getPersistedUser, persistUser, resetUser } from '@/utils/persistLogin';

import type { RefreshTokenPayload, UserState } from './user.types';

const initialState: UserState = {
  email: null,
  accessToken: '',
  refreshToken: '',
};

/** При создании слайса, получаем ранее сохраненного пользователя, если такой был */
const persistedUser = getPersistedUser();

export const userSlice = createSlice({
  name: 'user',
  initialState: persistedUser ? persistedUser : initialState,
  reducers: {
    logIn(state, action: PayloadAction<UserState>) {
      persistUser(action.payload);
      return { ...action.payload };
    },
    logOut(state) {
      state.accessToken = null;
      state.refreshToken = null;

      resetUser();
    },
    refreshToken(state, action: PayloadAction<RefreshTokenPayload>) {
      state.accessToken = action.payload.accessToken
        ? action.payload.accessToken
        : state.accessToken;
      state.refreshToken = action.payload.refreshToken
        ? action.payload.refreshToken
        : state.refreshToken;

      persistUser(state);
    },
  },
});

export default userSlice.reducer;
