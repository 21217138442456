import { useContext, useEffect } from 'react';

import {
  ServerError,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useSearchGroupMutation,
  useUpdateGroupMutation,
} from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/features/notifications';

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { parseUpdateGroupErrors } from '../groups.utils';

/**
 * Методы для взаимодействия с группой IMEI
 */
function useGroupEdit() {
  const notificationContext = useContext(NotificationContext);
  const [
    createGroup,
    {
      isLoading: isGroupCreating,
      error: createGroupError,
      isSuccess: isCreateGroupSuccess,
    },
  ] = useCreateGroupMutation();

  const [
    updateGroup,
    {
      isLoading: isGroupUpdating,
      error: updateGroupError,
      isSuccess: isUpdateGroupSuccess,
    },
  ] = useUpdateGroupMutation();

  const [
    deleteGroup,
    {
      isLoading: isGroupDeleting,
      error: deleteGroupError,
      isSuccess: isDeleteGroupSuccess,
    },
  ] = useDeleteGroupMutation();

  const [
    searchGroup,
    {
      isLoading: isGroupSearching,
      error: searchGroupError,
      isSuccess: isSearchGroupSuccess,
    },
  ] = useSearchGroupMutation();

  // Обработка ошибки создания группы
  useEffect(() => {
    if (!createGroupError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      createGroupError as unknown as ServerError,
    );

    notificationContext.showError(
      'Произошла ошибка при создании группы',
      messages,
    );
    console.error('Произошла ошибка при создании группы:', createGroupError);
  }, [createGroupError, notificationContext]);

  // Обработка ошибки обновления группы
  useEffect(() => {
    if (!updateGroupError) {
      return;
    }

    if (!('data' in updateGroupError)) {
      notificationContext.showError(
        'Произошла непредвиденная ошибка при редактировании группы',
      );

      console.error(
        'Произошла непредвиденная ошибка при редактировании группы:',
        updateGroupError,
      );

      return;
    }

    const { imeiListErrorDescription, rangesErrorDescription, unknownErrors } =
      parseUpdateGroupErrors(updateGroupError.data);

    const unknownErrorMessages =
      getServerErrorDescriptions(unknownErrors as unknown as ServerError) ?? [];

    const combinedErrors = [
      ...imeiListErrorDescription,
      ...rangesErrorDescription,
      ...unknownErrorMessages,
    ];

    notificationContext.showError(
      'Произошла ошибка при редактировании группы',
      combinedErrors,
    );
    console.error(
      'Произошла ошибка при редактировании группы:',
      updateGroupError,
    );
  }, [updateGroupError, notificationContext]);

  // Обработка ошибки удаления группы
  useEffect(() => {
    if (!deleteGroupError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      deleteGroupError as unknown as ServerError,
    );

    notificationContext.showError(
      'Произошла ошибка при удалении группы',
      messages,
    );
    console.error('Произошла ошибка при удалении группы:', deleteGroupError);
  }, [deleteGroupError, notificationContext]);

  // Обработка ошибки поиска группы
  useEffect(() => {
    if (!searchGroupError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      searchGroupError as unknown as ServerError,
    );

    notificationContext.showError(
      'Произошла ошибка при поиске группы',
      messages,
    );
    console.error('Произошла ошибка при поиске группы:', searchGroupError);
  }, [searchGroupError, notificationContext]);

  return {
    createGroup,
    createGroupError,
    isGroupCreating,
    isCreateGroupSuccess,
    deleteGroup,
    deleteGroupError,
    isGroupDeleting,
    isDeleteGroupSuccess,
    updateGroup,
    isGroupUpdating,
    updateGroupError,
    isUpdateGroupSuccess,
    searchGroup,
    searchGroupError,
    isGroupSearching,
    isSearchGroupSuccess,
  };
}

export default useGroupEdit;
