import { BooksIcon, MagnifyingGlassIcon } from '@aq_mobile/ui-kit/icons';

import { MenuItem } from '@/components';

/**
 * Доступные пункты меню системы.
 */
export const MENU_ITEMS: Array<MenuItem> = [
  {
    key: '/groups',
    icon: <BooksIcon />,
    path: '/groups',
    label: 'Группы IMEI',
  },
  {
    key: '/search',
    icon: <MagnifyingGlassIcon />,
    path: '/search',
    label: 'Поиск группы по IMEI',
  },
];
