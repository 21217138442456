import { forwardRef, memo, useCallback } from 'react';
import { Button, Table, Typography } from '@aq_mobile/ui-kit';
import { MARGINS } from '@aq_mobile/ui-kit/utils';
import { Col, Row, TableColumnsType } from 'antd';

import {
  ERROR_LIST_PAGE_SIZE,
  ErrorListProps,
  ErrorRecord,
} from './ErrorList.types';

/**
 * Компонент для отображения списка ошибок.
 * value - компонента не принимает [] значения, т.к. undefined используется
 * для определения наличия значения и отображения или скрытия компонента.
 */
function ErrorList(
  { label = 'Ошибки импорта', value, onChange }: ErrorListProps,
  ref: React.Ref<HTMLDivElement>,
) {
  /**
   * Обработчик удаления одной записи об ошибке
   */
  const handleDeleteRecord = useCallback(
    (id: string) => {
      if (!value) {
        return;
      }

      const recordIndex = value.findIndex((record) => record.id === id);

      if (recordIndex < 0) {
        // Не должно происходить при нормальном использовании компонента.
        return;
      }

      const updatedValue = value.toSpliced(recordIndex, 1);
      // Когда все ошибки очищены, значение Input-а должно становиться undefined.
      const inputValue = updatedValue.length > 0 ? updatedValue : undefined;

      onChange?.(inputValue);
    },
    [onChange, value],
  );

  /**
   * Обработчик удаления всех записей об ошибках
   */
  const handleClearAll = useCallback(() => {
    onChange?.(undefined);
  }, [onChange]);

  const columnTemplate: TableColumnsType<ErrorRecord> = [
    {
      title: 'Описание',
      dataIndex: 'description',
      align: 'left',
    },
    {
      dataIndex: 'id',
      title: 'Действие',
      align: 'right',
      render: (id: string) => (
        <Button onClick={() => handleDeleteRecord(id)} danger type="primary">
          Удалить
        </Button>
      ),
    },
  ];

  return (
    <div ref={ref}>
      <Typography.Heading5
        style={{
          marginBlockStart: MARGINS.section * 2,
          marginBlockEnd: MARGINS.section,
        }}
      >
        {label}
      </Typography.Heading5>

      <Table
        dataSource={value}
        columns={columnTemplate}
        pagination={{
          pageSize: ERROR_LIST_PAGE_SIZE,
          hideOnSinglePage: true,
        }}
        rowKey="id"
      ></Table>
      <Row justify={'center'} style={{ marginBlockStart: MARGINS.element }}>
        <Col>
          <Button danger onClick={handleClearAll}>
            Очистить все ошибки
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default memo(forwardRef(ErrorList));
