import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '@/features/baseQuery';
import { IMEIGroup } from '@/features/groups';

import { TokenRequest, TokenResponse } from './api.types';

export const appConsoleApi = createApi({
  reducerPath: 'imeiApi',
  baseQuery: baseQuery,
  tagTypes: ['Groups', 'Group'],
  endpoints: (builder) => ({
    login: builder.mutation<TokenResponse, TokenRequest>({
      query: (data) => ({
        url: 'token/',
        method: 'POST',
        body: data,
      }),
    }),
    getGroups: builder.query<Array<IMEIGroup>, void>({
      query: () => ({
        url: `admin/imei-group/`,
        method: 'GET',
      }),
      providesTags: ['Groups'],
    }),
    getGroup: builder.query<IMEIGroup, number>({
      query: (id) => ({
        url: `admin/imei-group/${id}/`,
        method: 'GET',
      }),
      providesTags: ['Groups', 'Group'],
    }),
    createGroup: builder.mutation<IMEIGroup, Partial<IMEIGroup>>({
      query: (data) => ({
        url: 'admin/imei-group/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Groups'],
    }),
    updateGroup: builder.mutation<IMEIGroup, IMEIGroup>({
      query: (data) => ({
        url: `admin/imei-group/${data.id}/`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Group'],
    }),
    deleteGroup: builder.mutation<void, number>({
      query: (id) => ({
        url: `admin/imei-group/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Groups', 'Group'],
    }),
    searchGroup: builder.mutation<Array<IMEIGroup>, number>({
      query: (imei) => ({
        url: 'admin/search-group-by-imei/',
        method: 'POST',
        body: { IMEI: imei },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetGroupsQuery,
  useGetGroupQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useSearchGroupMutation,
} = appConsoleApi;
