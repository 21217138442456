const digitsOnlyRegEx = /^\d*$/;

/**
 * Возвращает true, если строка содержит только цифры.
 * Числа типа 1.0001 или 1e15 не подходят.
 */
function isDigitsOnly(str: string) {
  return digitsOnlyRegEx.test(str);
}

export default isDigitsOnly;
