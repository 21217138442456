import { RuleObject } from 'antd/es/form';

import { ErrorRecord } from './ErrorList.types';

/**
 * Валидатор таблицы с ошибками.
 * Все ошибки должны быть обработаны, чтобы форма считалась валидной.
 */
export function shouldBeEmptyValidator(_: RuleObject, value: any) {
  if (Array.isArray(value) && value.length > 0) {
    return Promise.reject('Все ошибки должны быть просмотрены и очищены');
  }

  return Promise.resolve();
}

/**
 * Конвертирует массив в описанием ошибок в массив ошибок, поддерживаемых компонентом.
 */
export function errorsToErrorsList(errors: Array<string>): Array<ErrorRecord> {
  return errors.map((error) => ({
    id: crypto.randomUUID(),
    description: error,
  }));
}
