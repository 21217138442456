import { useContext, useEffect } from 'react';

import { ServerError, useGetGroupsQuery } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/features/notifications';

/**
 * Хук для управления списка групп IMEI
 */
function useGroups() {
  const notificationContext = useContext(NotificationContext);
  const {
    isLoading: isGroupsLoading,
    data,
    error: groupsError,
  } = useGetGroupsQuery();

  const groups = data || [];

  useEffect(() => {
    if (!groupsError) {
      return;
    }

    const errorMessages = getServerErrorDescriptions(
      groupsError as unknown as ServerError,
    );

    notificationContext.showError('Ошибка получения групп', errorMessages);
    console.error('Ошибка получения списка групп:', groupsError);
  }, [groupsError, notificationContext]);

  return {
    isGroupsLoading,
    groups,
    groupsError,
  };
}

export default useGroups;
