import Papa from 'papaparse';
import readXlsxFile from 'read-excel-file';

import { parseIMEI } from '@/features/imei';

import { IMEIImportResult } from './IMEIImportModal.types';

enum SupportedExtensions {
  csv = '.csv',
  xlsx = '.xlsx',
}

export const IMPORT_MIME =
  'text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

function parseCSV(file: File, completeFn: (result: IMEIImportResult) => void) {
  Papa.parse(file, {
    delimiter: ',',
    skipEmptyLines: true,
    complete: (result) => {
      const imei: Array<number> = [];
      const errors: Array<string> = [];

      result.data.forEach((row) => {
        (row as Array<string>).forEach((col: string | null) => {
          // Пропускаем все пустые значения.
          // Они не являются ошибкой.
          if (!col || !col.length) {
            return;
          }

          try {
            imei.push(parseIMEI(col));
          } catch (e) {
            errors.push(col);
          }
        });
      });

      completeFn({
        imei,
        errors,
      });
    },
  });
}

function parseXLSX(file: File, completeFn: (result: IMEIImportResult) => void) {
  readXlsxFile(file).then((result) => {
    const imei: Array<number> = [];
    const errors: Array<string> = [];

    result.forEach((row) => {
      row.forEach((col) => {
        if (!col) {
          return;
        }

        const stringValue = col.toString();

        try {
          imei.push(parseIMEI(stringValue));
        } catch {
          errors.push(stringValue);
        }
      });
    });

    completeFn({
      imei,
      errors,
    });
  });
}

/**
 * Возвращает Promise, который после разрешения будет содержать
 * распознанные IMEI и массив ошибок.
 * @param file файл, для парсинга
 */
export async function parseIMEIs(file: File): Promise<IMEIImportResult> {
  const resultPromise = new Promise((resolve) => {
    if (file.name.endsWith(SupportedExtensions.csv)) {
      parseCSV(file, resolve);
      return;
    }

    parseXLSX(file, resolve);
  });

  return resultPromise as Promise<IMEIImportResult>;
}
