export type ErrorListProps = {
  label?: string;
  value?: Array<ErrorRecord>;
  onChange?: (value?: Array<ErrorRecord>) => void;
};

export type ErrorRecord = {
  id: string;
  description: string;
};

export const ERROR_LIST_PAGE_SIZE = 25;
