import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Typography } from '@aq_mobile/ui-kit';
import { LockIcon, PersonIcon } from '@aq_mobile/ui-kit/icons';
import { settings } from '@aq_mobile/ui-kit/utils';
import { type FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Flex, Form, Input } from 'antd';

import { LoginCard } from '@/components';
import { useLoginMutation } from '@/features/api';
import { useAppDispatch, useAppSelector } from '@/features/hooks';
import { rootActions, rootSelectors } from '@/features/store';
import { ThemeContext } from '@/utils';
import { getRestorePasswordLink } from '@/utils';

type FormType = {
  userName?: string;
  password?: string;
};

const ACCESS_ERROR_MESSAGES = {
  incorrectLogin:
    'Такой комбинации эл. почты (имени пользователя) и пароля не существует.',
  noActiveAccount: 'Нет активного аккаунта с указанными данными',
  genericError: 'Произошла ошибка. На данный момент вход невозможен.',
};

export default function Login() {
  const themeContext = useContext(ThemeContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(rootSelectors.users.isUserLoggedInSelector);
  const [login, { isLoading, error }] = useLoginMutation();
  const [accessErrorMessage, setAccessErrorMessage] = useState('');
  const [form] = Form.useForm();

  /** Обрабатываем отдельно ошибку при логине от сервера */
  useEffect(() => {
    if (!error) {
      return;
    }

    if ((error as FetchBaseQueryError).status === 400) {
      setAccessErrorMessage(ACCESS_ERROR_MESSAGES.incorrectLogin);
      return;
    }

    if ((error as FetchBaseQueryError).status === 401) {
      setAccessErrorMessage(ACCESS_ERROR_MESSAGES.noActiveAccount);
      return;
    }

    setAccessErrorMessage(ACCESS_ERROR_MESSAGES.genericError);
  }, [error]);

  /** Если пользователь уже авторизован, отправляем его на страницу "Начало" */
  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    navigate('/groups', { replace: true });
  }, [isLoggedIn, navigate]);

  /** Загрузка сохраненного имени пользователя, если такой есть */
  useEffect(() => {
    const userName = settings.get<string | null>('userName');
    if (!userName) {
      return;
    }

    form.setFieldValue('userName', userName);
  }, [form]);

  /** Обработчик отправки формы авторизации */
  const onLogin = async (formValues: { [key: string]: unknown }) => {
    const userName = (formValues['userName'] as string).trim();
    const password = (formValues['password'] as string).trim();

    if (!userName.length || !password.length) {
      return;
    }

    try {
      const tokenPair = await login({ username: userName, password }).unwrap();

      dispatch(
        rootActions.user.logIn({
          accessToken: tokenPair.access,
          refreshToken: tokenPair.refresh,
          email: tokenPair.user_email,
        }),
      );

      // При успешном логине сохраняем почту пользователя в localStorage,
      // чтобы потом можно было ее восстановить
      settings.set('userName', userName);

      navigate('/groups');
    } catch (error) {
      console.error('[IMEI] Login Error:', error);
    }
  };

  const handleFormValueChange = useCallback(() => {
    // При изменении полей на форме, скрываем сообщение об ошибке
    // при логине, если оно было
    if (!accessErrorMessage) {
      return;
    }

    setAccessErrorMessage('');
  }, [accessErrorMessage]);

  const restoreLink = getRestorePasswordLink(themeContext.theme);

  return (
    <LoginCard
      style={{
        alignSelf: 'center',
        flex: '1',
        maxWidth: 512,
      }}
    >
      <Flex justify="center" align="center" vertical>
        <Typography.Heading4
          style={{ textTransform: 'uppercase', marginBlockEnd: 16 }}
        >
          Aquarius IMEI
        </Typography.Heading4>

        <Typography.ParagraphM style={{ marginBlockEnd: 42 }}>
          Вход в учетную запись
        </Typography.ParagraphM>
      </Flex>

      <Form
        name="login"
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onValuesChange={handleFormValueChange}
        onFinish={onLogin}
        disabled={isLoading}
        form={form}
      >
        <Form.Item<FormType>
          name="userName"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите эл. почту или имя пользователя',
            },
          ]}
        >
          <Input
            prefix={<PersonIcon />}
            placeholder="Имя пользователя или эл. почта"
          />
        </Form.Item>

        <Form.Item<FormType>
          name="password"
          rules={[{ required: true, message: 'Пожалуйста, укажите пароль' }]}
        >
          <Input.Password prefix={<LockIcon />} placeholder="Пароль" />
        </Form.Item>

        {accessErrorMessage && (
          <Form.Item>
            <Typography.TextS type="danger">
              {accessErrorMessage}
            </Typography.TextS>
          </Form.Item>
        )}

        <Form.Item css={{ textAlign: 'center' }}>
          <Link to={restoreLink} style={{ textAlign: 'center' }}>
            Восстановить пароль
          </Link>
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            pending={isLoading}
            style={{
              display: 'block',
              width: 212,
              margin: '0 auto',
            }}
          >
            Войти
          </Button>
        </Form.Item>
      </Form>
    </LoginCard>
  );
}
