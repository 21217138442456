/**
 * Возвращает массив значений дубликатов.
 */
function getDuplicates<T>(values: Array<T>): Array<T> {
  const duplicates: Array<T> = [];

  values.forEach((value) => {
    let occurrence = 0;
    values.forEach((value2) => {
      if (value !== value2) {
        return;
      }

      occurrence += 1;
    });

    if (occurrence > 1 && !duplicates.includes(value)) {
      duplicates.push(value);
    }
  });

  return duplicates;
}

export default getDuplicates;
