import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ContentCard } from '@aq_mobile/ui-kit';
import { Form, Input, Spin } from 'antd';

import { GROUP_NAME_MAX_LENGTH } from '@/constants';
import { useGroupEdit } from '@/features/groups';

type NewGroupForm = {
  id: number;
  name: string;
};

/**
 * Компонент создания нового приложения в магазине.
 */
function GroupNew() {
  const navigate = useNavigate();
  const { createGroup, isGroupCreating } = useGroupEdit();
  const [form] = Form.useForm();

  const addNewGroup = useCallback(
    async (values: NewGroupForm) => {
      const group = await createGroup({
        name: values.name,
        ranges: [],
        imei_list: [],
      }).unwrap();

      navigate(`/groups/${group.id}`);
    },
    [createGroup, navigate],
  );

  const handleCancel = useCallback(() => {
    form.resetFields();
    navigate('/groups');
  }, [form, navigate]);

  return (
    <ContentCard>
      <ContentCard.Header title="Добавить группу IMEI" />
      <ContentCard.Body>
        <Spin spinning={isGroupCreating}>
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ group: 'all' }}
            onFinish={addNewGroup}
            labelWrap
            colon={false}
            requiredMark={false}
            labelAlign="left"
          >
            <Form.Item<NewGroupForm>
              label="Название"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, укажите название группы',
                },
                {
                  max: GROUP_NAME_MAX_LENGTH,
                  message: `Превышена максимальная длина имени группы ${GROUP_NAME_MAX_LENGTH}`,
                },
              ]}
            >
              <Input showCount maxLength={GROUP_NAME_MAX_LENGTH} />
            </Form.Item>
          </Form>
        </Spin>
      </ContentCard.Body>

      <ContentCard.Footer
        buttons={
          <>
            <Button type="default" onClick={handleCancel}>
              Отменить
            </Button>
            <Button
              type="primary"
              onClick={form.submit}
              disabled={isGroupCreating}
            >
              Сохранить
            </Button>
          </>
        }
      ></ContentCard.Footer>
    </ContentCard>
  );
}

export default GroupNew;
