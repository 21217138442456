import userReducer, { userSlice } from './user.reducer';
import { isUserLoggedInSelector, userMailSelector } from './user.selectors';
export * from './user.types';

const userActions = {
  ...userSlice.actions,
};

const userSelectors = {
  isUserLoggedInSelector,
  userMailSelector,
};

export { userActions, userReducer, userSelectors };
