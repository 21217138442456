import { memo, useCallback } from 'react';
import { FileInput, Modal } from '@aq_mobile/ui-kit';
import { ExclamationMarkCircleOutlineIcon } from '@aq_mobile/ui-kit/icons';
import { Form } from 'antd';

import { IMEIImportModalProps } from './IMEIImportModal.types';
import { IMPORT_MIME, parseIMEIs } from './IMEIImportModal.utils';

/**
 * Компонент для импорта номеров IMEI из файла.
 * Поддерживаемые форматы CSV, XLSX
 */
function IMEIImportModal({ open, onOk, onCancel }: IMEIImportModalProps) {
  const [form] = Form.useForm<{ file: File }>();

  const handleModalOk = useCallback(async () => {
    form.submit();
  }, [form]);

  const handleSubmit = useCallback(
    async ({ file }: { file: File }) => {
      const result = await parseIMEIs(file);
      onOk(result);
      form.resetFields();
    },
    [form, onOk],
  );

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel?.();
  }, [form, onCancel]);

  return (
    <Modal
      title="Импорт IMEI"
      open={open}
      onOk={handleModalOk}
      onCancel={handleCancel}
    >
      <>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            label="Файл"
            tooltip={{
              title:
                'Загружаемый файл должен быть в формате CSV или XLSX с одной колонкой, один IMEI в одной строке.',
              icon: <ExclamationMarkCircleOutlineIcon />,
            }}
            rules={[
              {
                required: true,
                message: 'Укажите файл для загрузки',
              },
            ]}
            name="file"
          >
            <FileInput accept={IMPORT_MIME} />
          </Form.Item>
        </Form>
      </>
    </Modal>
  );
}

export default memo(IMEIImportModal);
