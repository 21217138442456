import {
  type Action,
  combineReducers,
  configureStore,
  type ThunkAction,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { settingsReducer } from '@/features/settings';
import { userActions, userReducer, userSelectors } from '@/features/user';

import { appConsoleApi } from './api';

export const rootActions = {
  user: userActions,
};

export const rootSelectors = {
  users: userSelectors,
};

const rootReducer = combineReducers({
  user: userReducer,
  settings: settingsReducer,
  [appConsoleApi.reducerPath]: appConsoleApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(appConsoleApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
