import { useCallback, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '@aq_mobile/ui-kit';
import { settings } from '@aq_mobile/ui-kit/utils';
import { ConfigProvider } from 'antd';
import localeRU from 'antd/locale/ru_RU';

import { App } from '@/components';
import {
  NotificationContext,
  useNotifications,
} from '@/features/notifications';
import { darkTheme, lightTheme, type ThemePalette } from '@/themes';
import { ThemeContext } from '@/utils/theme-context';

/**
 * Корневой элемент приложения.
 * Настраивает контексты уведомлений, темы и является оберткой для роутов в приложении.
 */
export default function Root() {
  const [theme, setTheme] = useState<ThemePalette>(getThemePalette);
  const { contextHolder, notificationContextValue } = useNotifications();

  const goToMainPage = useCallback(() => {
    window.location.href = __APP_ROOT_URI__;
  }, []);

  const themeContextValue = useMemo(() => {
    return {
      theme: theme,
      setTheme: (theme: ThemePalette) => {
        settings.set('theme', theme);
        setGlobalTheme(theme);
        setTheme(theme);
      },
    };
  }, [theme]);

  const currentTheme = useMemo(
    () => (theme === 'light' ? lightTheme : darkTheme),
    [theme],
  );

  return (
    <>
      <ThemeContext.Provider value={themeContextValue}>
        <NotificationContext.Provider value={notificationContextValue}>
          <ConfigProvider theme={currentTheme} locale={localeRU}>
            <App>
              <ErrorBoundary
                buttonTitle="Вернуться на главную"
                onClick={goToMainPage}
              >
                <Outlet />
              </ErrorBoundary>
            </App>
          </ConfigProvider>
        </NotificationContext.Provider>
      </ThemeContext.Provider>
      {contextHolder}
    </>
  );
}
