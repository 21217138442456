import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContentCard, PaginationTotal, Table } from '@aq_mobile/ui-kit';
import styled from '@emotion/styled';
import { Flex, Form, Input, Space, Spin, TableColumnsType } from 'antd';
import localeRU from 'antd/locale/ru_RU';

import { IMEIGroup, useGroupEdit } from '@/features/groups';
import { IMEI_LENGTH } from '@/features/imei';
import { NotificationContext } from '@/features/notifications';

const TableStyled = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
` as unknown as typeof Table;

const columnsTemplate: TableColumnsType<IMEIGroup> = [
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Группа IMEI',
  },
];

const PAGE_SIZE = 20;

type SearchForm = {
  imei: string;
};

/**
 * Форма поиска групп по IMEI.
 */
function Search() {
  const navigate = useNavigate();
  const location = useLocation();
  const [groups, setGroups] = useState<Array<IMEIGroup>>([]);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const { searchGroup, isGroupSearching } = useGroupEdit();
  const notificationContext = useContext(NotificationContext);
  const [form] = Form.useForm<SearchForm>();

  /**
   * При щелчке на результат поиска переходим на форму редактирования группы.
   * Сохраняем значение строки поиска, на случай, если его придется
   * восстановить, потому что пользователь решил вернуться к результатам
   * поиска, через кнопку "Назад" на странице группы.
   */
  const handleGoToGroup = useCallback(
    ({ id }: { id: number }) => {
      navigate(`/groups/${id}`, {
        state: {
          searchIMEI: form.getFieldValue('imei'),
        },
      });
    },
    [form, navigate],
  );

  const handleSearch = useCallback(
    async (searchTerm: string) => {
      // Когда очищаем значение с помощью крестика, срабатывает поиск.
      // Поэтому пропускаем пустые значения.
      if (!searchTerm) {
        return;
      }

      try {
        await form.validateFields();
        const result = await searchGroup(Number(searchTerm)).unwrap();

        if (!result.length) {
          notificationContext.showWarning('Ничего не найдено');
          setCurrentTablePage(1);
          setGroups([]);
          return;
        }

        setCurrentTablePage(1);
        setGroups(result);
      } catch {
        // Ошибка выбрасывается, когда форма  не валидна.
      }
    },
    [form, notificationContext, searchGroup],
  );

  /**
   * Обработка восстановления строки поиска, в случае, если
   * пользователь вернулся в этот раздел из формы
   * редактирования, найденной группы.
   */
  useEffect(() => {
    const { state: locationState } = location;

    if (!locationState || !locationState.searchIMEI) {
      return;
    }

    form.setFieldValue('imei', locationState.searchIMEI);
    handleSearch(locationState.searchIMEI);
  }, [form, handleSearch, location]);

  return (
    <>
      <ContentCard>
        <ContentCard.Header title="Поиск групп по IMEI" />
        <ContentCard.Body>
          <Spin spinning={isGroupSearching}>
            <Form
              form={form}
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              labelAlign="left"
            >
              <Flex justify="space-between">
                <Space.Compact>
                  <Form.Item
                    name="imei"
                    rules={[
                      {
                        len: IMEI_LENGTH,
                        message: `IMEI должен содержать ${IMEI_LENGTH} символов`,
                      },
                      {
                        required: true,
                        message: `Укажите IMEI для поиска`,
                      },
                      {
                        pattern: /^\d+$/,
                        message: 'IMEI должен содержать только цифры',
                      },
                    ]}
                  >
                    <Input.Search
                      addonBefore="IMEI"
                      enterButton="Искать"
                      onSearch={(e) => handleSearch(e)}
                      count={{
                        show: true,
                        max: IMEI_LENGTH,
                      }}
                      maxLength={IMEI_LENGTH}
                      allowClear
                    />
                  </Form.Item>
                </Space.Compact>
                <PaginationTotal
                  currentPage={currentTablePage}
                  pageSize={PAGE_SIZE}
                  total={groups.length || 0}
                />
              </Flex>
            </Form>

            <TableStyled
              dataSource={groups}
              columns={columnsTemplate}
              rowKey={'id'}
              pagination={{
                pageSize: PAGE_SIZE,
                hideOnSinglePage: false,
                onChange(page) {
                  setCurrentTablePage(page);
                },
              }}
              onRow={(record) => {
                return {
                  onClick: (e) => {
                    handleGoToGroup(record);
                  },
                };
              }}
              scroll={{ x: 768 }}
            />
          </Spin>
        </ContentCard.Body>
      </ContentCard>
    </>
  );
}

export default Search;
