import { useMemo } from 'react';
import { notification } from 'antd';

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { NotificationFunction } from '../notifications.types';

/**
 * Хук, конфигурирующий настройки отображения всплывающих уведомлений.
 */
function useNotifications() {
  const [notificationApi, contextHolder] = notification.useNotification();

  const notificationContextValue = useMemo(() => {
    const showError: NotificationFunction = (message, description) => {
      if (Array.isArray(description)) {
        notificationApi.error({
          message,
          description: description.map((d, index) => <p key={index}>{d}</p>),
          duration: 20,
        });
        return;
      }

      notificationApi.error({
        message,
        description,
        duration: 20,
      });
    };

    const showSuccess: NotificationFunction = (message, description) => {
      if (Array.isArray(description)) {
        notificationApi.success({
          message,
          description: description.map((d, index) => <p key={index}>{d}</p>),
          duration: 4,
        });
        return;
      }

      notificationApi.success({
        message,
        description,
        duration: 4,
      });
    };

    const showWarning: NotificationFunction = (message, description) => {
      if (Array.isArray(description)) {
        notificationApi.warning({
          message,
          description: description.map((d, index) => <p key={index}>{d}</p>),
          duration: 15,
        });
        return;
      }

      notificationApi.warning({
        message,
        description,
        duration: 15,
      });
    };

    return {
      notificationApi: notificationApi,
      showError,
      showSuccess,
      showWarning,
    };
  }, [notificationApi]);

  return {
    notificationContextValue,
    contextHolder,
  };
}

export default useNotifications;
