import { useContext, useEffect } from 'react';

import { ServerError, useGetGroupQuery } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/features/notifications';

/**
 * Хук, для получения группы IMEI по идентификатору.
 */
function useGroup(id: number | undefined) {
  const notificationContext = useContext(NotificationContext);
  const {
    data: group,
    isLoading: isGroupLoading,
    error: groupError,
    isSuccess: isGroupSuccess,
  } = useGetGroupQuery(id || 0, {
    skip: Boolean(!id),
  });

  useEffect(() => {
    if (!groupError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      groupError as unknown as ServerError,
    );

    notificationContext.showError(
      'Ошибка получения группы с сервера',
      messages,
    );
    console.error(`Ошибка получения группы с id=${id} с сервера:`, groupError);
  }, [groupError, id, notificationContext]);

  return {
    group,
    isGroupLoading,
    groupError,
    isGroupSuccess,
  };
}

export default useGroup;
