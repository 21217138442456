import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { Button, Typography } from '@aq_mobile/ui-kit';
import {
  ChevronBackwardIcon,
  ChevronForwardIcon,
  ExitIcon,
  GearIcon,
} from '@aq_mobile/ui-kit/icons';
import { BreakPoints } from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Avatar, Dropdown, Flex, Layout } from 'antd';

import { MenuLogo } from '@/components';
import { useAppSelector } from '@/features/hooks';
import { userMailSelector } from '@/features/user/user.selectors';

const userMenu = [
  {
    key: 'settings',
    label: (
      <Flex align="center" gap={8}>
        <GearIcon style={{ fontSize: 20 }} />
        Настройки
      </Flex>
    ),
  },
  {
    key: 'logout',
    label: (
      <Flex align="center" gap={8}>
        <ExitIcon style={{ fontSize: 20 }} />
        Выйти
      </Flex>
    ),
  },
];

const ButtonCollapseStyled = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'collapsed',
})<{ collapsed?: boolean }>`
  &.ant-btn.ant-btn-lg {
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.collapsed ? '30px' : '40px')};
    font-size: 20px;
    transform: translateX(${(props) => (props.collapsed ? '0' : '-40px')});
    border-top-right-radius: ${(props) => (props.collapsed ? '8px' : '0')};
    border-bottom-right-radius: ${(props) => (props.collapsed ? '8px' : '0')};
    border-top-left-radius: ${(props) => (props.collapsed ? '0' : '8px')};
    border-bottom-left-radius: ${(props) => (props.collapsed ? '0' : '8px')};
  }
`;

const ButtonUserStyled = styled(Button)`
  &.ant-btn {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  & .ant-typography {
    display: none;
  }

  @media ${BreakPoints.sm} {
    & .ant-typography {
      display: inline-block;
    }
  }
`;

const UserNameStyled = styled(Typography.TextS)`
  display: none !important;

  @media ${BreakPoints.md} {
    display: inline-block !important;
  }
`;

const LogoStyled = styled(MenuLogo)`
  flex: 1;
  font-size: 14px !important;

  @media ${BreakPoints.xs} {
    font-size: 16px !important;
  }
`;

const HeaderStyled = styled(Layout.Header)`
  border-bottom-right-radius: 16px;
  padding-right: 12px !important;
  gap: 8px;
  display: flex;
  align-items: center;

  @media ${BreakPoints.xs} {
    padding-right: 24px !important;
    gap: 16px;
  }
`;

/** Компонент заголовка приложения */
function Header({
  isSiderCollapsed = false,
  onCollapseClick,
}: {
  isSiderCollapsed: boolean;
  onCollapseClick?: () => void;
}) {
  const navigate = useNavigate();
  const userMail = useAppSelector(userMailSelector) || 'Пользователь';

  const handleMenuClick = useCallback(
    ({ key }: { key: string }) => {
      switch (key) {
        case 'settings':
          navigate('/profile', { state: { login: userMail } });
          return;
        case 'logout':
        default:
          navigate('/logout');
          return;
      }
    },
    [navigate, userMail],
  );

  return (
    <HeaderStyled>
      <ButtonCollapseStyled
        collapsed={isSiderCollapsed}
        type="primary"
        icon={
          isSiderCollapsed ? <ChevronForwardIcon /> : <ChevronBackwardIcon />
        }
        size="large"
        onClick={onCollapseClick}
      />
      <LogoStyled hidden={!isSiderCollapsed} />
      <Flex justify="space-between" gap={16} wrap="wrap">
        <Dropdown
          trigger={['click', 'contextMenu']}
          menu={{ items: userMenu, onClick: handleMenuClick }}
          placement="bottom"
        >
          <ButtonUserStyled
            type="text"
            shape="default"
            size="large"
            style={{ fontSize: 24 }}
          >
            <Avatar size={24} icon={<UserOutlined />} />
            <UserNameStyled
              style={{
                fontWeight: 500,
              }}
            >
              {userMail}
            </UserNameStyled>
          </ButtonUserStyled>
        </Dropdown>
      </Flex>
    </HeaderStyled>
  );
}

export default Header;
